<template>
    <div class="recovery">
        <div class="row recovery-content">
            <div class="col-12 recovery-content-card">
                <div class="row justify-content-center">
                    <div class="col-11 col-md-auto p-0 ">
                        <b-overlay :show="sendingData" rounded="sm" class="card">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <p class="text-center mb-4">
                                    <img src="@/assets/images/tendencys.svg" class=" img-80" alt="">
                                </p>
                                <div class="text-center mb-4">
                                    <h3 class=" mb-2" v-text="$t('recoveryPassword.title')"></h3>
                                    <p class="f-w-500" v-text="$t('recoveryPassword.message2')"></p>
                                </div>
                                <p class="text-center mb-4">
                                    <b-button variant="light" pill disabled>
                                        <span v-text="userEmail"></span>
                                    </b-button>
                                </p>
                                <b-alert variant="danger" dismissible fade :show="showAlert">
                                    <i class=" fa fa-warning "></i> <span
                                        v-text=" $t('recoveryPassword.alertRecovery') ">
                                    </span>
                                </b-alert>
                                <v-observer tag="form" ref="formRecoveryPassword"
                                    @submit.prevent="fnApiUpdatePassword()">

                                    <v-validation rules="required|validPass" v-slot="{ validated,passed,errors }"
                                        :name="$t('recoveryPassword.newPassword')">
                                        <b-form-group :label="$t('recoveryPassword.newPassword')" label-for="input-formatter">
                                            <b-input-group>
                                                <b-form-input v-model="recovery.password" type="password"
                                                    :state=" (validated) ? passed : null " id="userPassword"
                                                    placeholder="*******">
                                                </b-form-input>
                                            </b-input-group>
                                            <b-form-invalid-feedback :state="(validated) ? passed : null"
                                                v-text="errors[0]">
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </v-validation>
                                    <v-validation rules="required|validPass" v-slot="{ validated,passed,errors }"
                                        :name="$t('recoveryPassword.repeatPassword')">
                                        <b-form-group :label="$t('recoveryPassword.repeatPassword')"
                                            label-for="input-formatter">
                                            <b-input-group>
                                                <b-form-input v-model="recovery.password_confirmation" type="password"
                                                    :state=" (validated) ? passed : null " id="userRepeatPassword"
                                                    placeholder="*******">
                                                </b-form-input>
                                            </b-input-group>
                                            <b-form-invalid-feedback :state="(validated) ? passed : null"
                                                v-text="errors[0]">
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </v-validation>
                                    <b-alert variant="danger" fade
                                        :show="recovery.password != recovery.password_confirmation">
                                        <i class=" fa fa-warning "></i> <span
                                            v-text=" $t('recoveryPassword.alertPasswords') "></span>
                                    </b-alert>
                                </v-observer>
                                <p class="text-right">
                                    <b-button variant="primary" @click="fnApiUpdatePassword()"
                                        :disabled="sendingData || recovery.password != recovery.password_confirmation || (recovery.password == null || recovery.password_confirmation == null)">
                                        <span class="text-capitalize" v-text="$t('general.recovery')"
                                            v-if=" !sendingData "></span>
                                        <i class="fa fa-spinner fa-spin " v-else></i>
                                    </b-button>
                                </p>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return ({
                sendingData: false,
                showAlert: false,
                userEmail: null,
                recovery: {
                    recovery: null,
                    password: null,
                    password_confirmation: null,
                },
            })
        },
        methods: {
            async fnApiUpdatePassword() {
                if (await this.$refs['formRecoveryPassword'].validate()) {
                    this.sendingData = true;
                    let tempRoute = `/accounts/login?email=${this.userEmail}&recovery_password=true`;
                    if(this.$route.query.site_id){
                        tempRoute = tempRoute + `&site_id=${this.$route.query.site_id}`;
                    }
                    await axiosAccount.put('me/recovery', this.recovery).then((response) => {
                        this.$router.push(tempRoute);
                    }).catch((error) => {
                        this.showAlert = true;
                    });
                    setTimeout(() => {
                        this.sendingData = false;
                    }, 500);
                }
            }
        },
        beforeMount() {
            this.userEmail = this.$route.query.email;
        },
        mounted() {
            this.recovery.recovery = this.$route.params.id;
        }
    }
</script>


<style lang="scss">
    .recovery {
        background-color: black;
        padding-top: 25vh;

        .recovery-content {
            min-height: 75vh;
            background-color: white;

            .recovery-content-card {
                .card {
                    min-height: 500px;
                    width: 450px;
                }

                transform: translateY(-10vh);

                @media only screen and (max-width: 767px) {
                    .card {
                        min-height: 500px;
                        width: 100%;
                    }

                    transform: translateY(-15vh);
                }
            }

            @media only screen and (max-width: 767px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
</style>